<template>
  <b-card
    no-body
  >
    <b-card-header>
      <b-card-title>
        <div class="d-flex">
          <feather-icon
            icon="CompassIcon"
            size="19"
          />
          <h4 class="mb-0 ml-50">
            {{ $t('Coordinates') }}
          </h4>
        </div>
      </b-card-title>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col cols="12">
          <div id="location">
            <br>
            <l-map
              ref="map"
              :center="[
                location.position.lat || defaultLocation.lat,
                location.position.lng || defaultLocation.lng
              ]"
              :zoom.sync="map.options.zoom"
              :options="map.options"
              :min-zoom="map.options.minZoom"
              :max-zoom="map.options.maxZoom"
              style="height: 240px; width: 100%"
              @dblclick="onMapClick"
            >
              <l-tile-layer :url="tileProvider.url" />
              <!--              <l-geosearch :options="geoSearchOptions" />-->
              <l-marker
                v-if="location.position.lat && location.position.lng"
                visible
                :draggable="true"
                :lat-lng.sync="location.position"
                @dragstart="dragging = true"
                @dragend="coordinatesAreChanged()"
              />
              <l-circle
                v-if="location.radius"
                ref="circle"
                :lat-lng.sync="location.position"
                :radius="location.radius"
                color="red"
              />
            </l-map>
            <b-card-text>
              <b-form>
                <b-form-group
                  :label="$t('Address')"
                  label-for="address"
                >
                  <b-form-input
                    id="address"
                    ref="autocomplete"
                    v-model="location.formatted_address"
                    disabled
                  />
                </b-form-group>
                <b-row>
                  <b-col
                    md="12"
                    lg="6"
                    xl="4"
                  >
                    <b-form-group
                      :label="$t('Country')"
                      label-for="country"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('Country')"
                        rules="required"
                      >
                        <b-form-input
                          id="country"
                          v-model="location.address.country"
                          v-capitalize-first-letter
                          trim
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    md="12"
                    lg="6"
                    xl="4"
                  >
                    <b-form-group
                      :label="$t('State')"
                      label-for="state"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('State')"
                        rules="required"
                      >
                        <b-form-input
                          id="state"
                          v-model="location.address.state"
                          v-capitalize-first-letter
                          trim
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    md="12"
                    xl="4"
                  >

                    <b-form-group
                      :label="$t('Locality')"
                      label-for="locality"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('Locality')"
                        rules="required"
                      >
                        <b-form-input
                          id="locality"
                          v-model="location.address.locality"
                          v-capitalize-first-letter
                          trim
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>

                  </b-col>
                </b-row>
                <b-row>
                  <b-col
                    cols="12"
                    xl="8"
                    md="12"
                  >
                    <b-form-group
                      :label="$t('Street')"
                      label-for="road"
                    >
                      <b-form-input
                        id="road"
                        v-model="location.address.road"
                        v-capitalize-first-letter
                        trim
                      />
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="6"
                    xl="2"
                  >
                    <b-form-group
                      :label="$t('House')"
                      label-for="house_number"
                    >
                      <b-form-input
                        id="house_number"
                        v-model="location.address.house_number"
                        :disabled="!location.address.road"
                        trim
                      />
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="6"
                    xl="2"
                  >
                    <b-form-group
                      :label="$t('Office')"
                      label-for="apartment_number"
                    >
                      <b-form-input
                        id="apartment_number"
                        v-model="location.address.apartment_number"
                        :disabled="!location.address.house_number"
                        trim
                      />
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                  >
                    <b-form-group
                      :label="$t('Radius')"
                      label-for="serviceRadius"
                    >
                      <vue-slider
                        id="serviceRadius"
                        v-model="location.radius"
                        :direction="direction"
                        :min="1000"
                        :max="100000"
                        :interval="1000"
                        :tooltip="'none'"
                      />
                      {{ serviceRadius }}
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="6"
                  >
                    <b-form-group
                      :label="$t('Latitude')"
                      label-for="lat"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('Latitude')"
                        rules="required"
                      >
                        <b-form-input
                          id="lat"
                          v-model="location.position.lat"
                          :disabled="true"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>

                  </b-col>
                  <b-col
                    cols="6"
                  >
                    <b-form-group
                      :label="$t('Longitude')"
                      label-for="lng"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('Longitude')"
                        rules="required"
                      >
                        <b-form-input
                          id="lng"
                          v-model="location.position.lng"
                          :disabled="true"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-alert
                      variant="danger"
                      :show="!location.address_confirmed"
                      class="mb-0"
                    >
                      <div class="alert-body">
                        <feather-icon
                          icon="InfoIcon"
                          class="mr-50"
                        />
                        {{ $t('Address is unconfirmed') }}
                      </div>
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('Address')"
                        rules="required"
                      >
                        <b-form-input
                          :v-model="location.address_confirmed"
                          :disabled="true"
                          class="d-none"
                        />
                        <!--                        <small class="text-danger">{{ errors[0] }}</small>-->
                      </validation-provider>
                    </b-alert>
                    <b-alert
                      variant="success"
                      :show="location.address_confirmed"
                      class="mb-0"
                    >
                      <div class="alert-body">
                        <feather-icon
                          icon="CheckIcon"
                          class="mr-50"
                        />
                        {{ $t('Address is confirmed') }}

                      </div>
                    </b-alert>
                    <b-alert
                      variant="danger"
                      :show="!coordinatesTaken"
                      class="mb-0"
                    >
                      <div class="alert-body">
                        <feather-icon
                          icon="InfoIcon"
                          class="mr-50"
                        />
                        {{ $t('Coordinates have not been updated') }}
                      </div>
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('Coordinates')"
                        rules="required"
                      >
                        <b-form-input
                          :v-model="coordinatesTaken"
                          :disabled="true"
                          class="d-none"
                        />
                        <!--                        <small class="text-danger">{{ errors[0] }}</small>-->
                      </validation-provider>
                    </b-alert>
                    <b-alert
                      variant="success"
                      :show="coordinatesTaken"
                      class="mb-0"
                    >
                      <div class="alert-body">
                        <feather-icon
                          icon="CheckIcon"
                          class="mr-50"
                        />

                        {{ $t('Coordinates have been updated') }}
                      </div>
                    </b-alert>
                    <span
                      v-if="error"
                      class="text-danger"
                    >{{ error }}</span>
                  </b-col>
                  <b-col
                    cols="12"
                  >
                    <b-button
                      v-if="addressChanged && !error && !coordinatesTaken"
                      variant="primary"
                      block
                      @click="fetchLocationBySearchQuery(location.address, true)"
                    >
                      {{ $t('Update Coordinates') }}
                    </b-button>
                    <b-button
                      v-if="coordinatesTaken && !location.address_confirmed"
                      variant="primary"
                      block
                      @click="confirmAddress('customer')"
                    >
                      {{ $t('Confirm Address') }}
                    </b-button>
                  </b-col>
                </b-row>
              </b-form>
            </b-card-text>
          </div>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BForm,
  BFormInput,
  BFormGroup,
  BRow,
  BCol,
  BCard,
  BCardBody,
  BCardHeader,
  BCardTitle,
  BCardText,
  BButton,
  BAlert,
} from 'bootstrap-vue'
import VueSlider from 'vue-slider-component'
import '@core/scss/vue/libs/vue-slider.scss'
import {
  LMap, LTileLayer, LMarker, LCircle,
} from 'vue2-leaflet'
import { heightFade } from '@core/directives/animations'
import { capitalizeFirstLetter } from '@/directives/capitalize'
import { Icon } from 'leaflet'
import { OpenStreetMapProvider } from 'leaflet-geosearch'
import LGeosearch from 'vue2-leaflet-geosearch'

import 'leaflet/dist/leaflet.css'
import 'leaflet-geosearch/dist/geosearch.css'
import store from '@/store/index'

import * as locationService from '@/utils/location/location'
import router from '@/router'
/* eslint-disable global-require */
import { getFormattedAddress } from '@/mixins/functions'
import { ValidationProvider } from 'vee-validate'
import {
  required, confirmed, min,
} from '@validations'
// eslint-disable-next-line no-underscore-dangle
delete Icon.Default.prototype._getIconUrl
Icon.Default.mergeOptions({
  // eslint-disable-next-line global-require
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  // eslint-disable-next-line global-require
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  // eslint-disable-next-line global-require
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
})
export default {
  components: {
    BForm,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    BCard,
    BCardBody,
    BCardHeader,
    BCardTitle,
    BCardText,
    BButton,
    BAlert,
    VueSlider,

    LMap,
    LTileLayer,
    LMarker,
    LCircle,
    LGeosearch,
    ValidationProvider,

  },
  directives: {
    'height-fade': heightFade,
    'capitalize-first-letter': capitalizeFirstLetter,
  },
  mixins: [getFormattedAddress],
  props: {
    data: {
      type: Object,
      required: true,
      default: () => {},
    },
    defaultLocation: {
      type: Object,
      default: () => ({
        lat: 49.444444,
        lng: 32.059722,
      }),
    },
  },
  data() {
    return {
      required,
      confirmed,
      point: this.data,
      fetchedAddress: {},
      dragging: false,
      loading: false,
      error: null,
      coordinatesTaken: false,
      addressChanged: false,
      location: {
        address: {
          country: '',
          country_code: '',
          postcode: '',
          locality: '',
          road: '',
          state: '',
          house_number: '',
          apartment_number: '',
        },
        formatted_address: '',
        address_confirmed: false,
        position: {
          lat: 0,
          lng: 0,
        },
        radius: 25000,
        viewport: {
          _southWest: {
            lat: 0,
            lng: 0,
          },
          _northEast: {
            lat: 0,
            lng: 0,
          },
        },

      },
      map: {
        options: {
          zoomControl: true,
          attributionControl: false,
          zoomSnap: true,
          zoom: 15,
          minZoom: 6,
          maxZoom: 18,
          tap: true,
          dragging: true,
          scrollWheelZoom: false,
        },
      },
      tileProvider: {
        attribution: '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
        url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      },
      geoSearchOptions: {
        provider: new OpenStreetMapProvider({
          params: {
            'accept-language': this.$i18n.locale, // render results in language
            // countrycodes: [this.$i18n.locale], // limit search results for country
          },
        }),
        autoClose: true,
        retainZoomLevel: false,
        animateZoom: true,
      },
      tooltip: {
        options: {
          permanent: true,
          direction: 'top',
          interactive: true,
          noWrap: true,
          opacity: 0.9,
        },
      },
    }
  },
  computed: {
    serviceRadius() {
      let km = 0
      let m = 0
      const { radius } = this.location
      if (radius) {
        km = Math.floor(radius / 1000)
        m = radius % 1000
      }
      return (m) ? `${km} km : ${m} m` : `${km} ${this.$t('km')}`
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = 'rtl'
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = 'ltr'
      return this.dir
    },
  },
  watch: {
    'location.position': {
      async handler() {
        await this.$nextTick(() => {
          this.setLocationViewport()
        })
      },
    },
    'location.radius': {
      deep: true,
      async handler() {
        await this.$nextTick(() => {
          this.setLocationViewport()
        })
      },
    },
  },
  mounted() {
    if (router.currentRoute.name === 'apps-service-offer-add') {
      this.fetchCurrentLocation()
    }
    this.$watch('data', newValue => {
      this.point = newValue
      this.setPosition(this.point.lat, this.point.lng)
      this.setRadius()
      this.setAddress()
      this.location.address_confirmed = this.point.address.address_confirmed
    })

    this.$watch('location.address', newValue => {
      this.location.formatted_address = this.getFormattedAddress(newValue)
      if (this.addressChanged) {
        this.error = false
      }
    }, { deep: 'true' })
    this.$watch('location.address_confirmed', newValue => {
      if (newValue) {
        this.$emit('updateLocation', this.location)
      }
    })
    this.$watch('location.address.country', () => {
      this.updateAddressConfirmation()
    })
    this.$watch('location.address.state', () => {
      this.updateAddressConfirmation()
    })
    this.$watch('location.address.locality', () => {
      this.updateAddressConfirmation()
    })
    this.$watch('location.address.road', newValue => {
      if (newValue === '') {
        this.location.address.house_number = ''
      }
      this.updateAddressConfirmation()
    })
    this.$watch('location.address.house_number', newValue => {
      if (newValue === '') {
        this.location.address.apartment_number = ''
      }
      this.updateAddressConfirmation()
    })
    this.$watch('location.address.apartment_number', () => {
      if (JSON.stringify(this.location.address) !== this.fetchedAddress) {
        this.coordinatesTaken = true
        this.unConfirmAddress()
      } else {
        this.coordinatesTaken = true
        this.confirmAddress()
      }
    })
    this.$refs.map.mapObject.on('geosearch/showlocation', this.onSearch)
  },
  methods: {
    updateAddressConfirmation() {
      this.addressChanged = JSON.stringify(this.location.address) !== this.fetchedAddress
      if (this.addressChanged) {
        this.coordinatesTaken = false
        this.unConfirmAddress()
      } else {
        this.coordinatesTaken = true
        this.location.address_confirmed = JSON.parse(this.fetchedAddress).address_confirmed
      }
    },
    locationIsValid(location) {
      if (typeof location === 'undefined' || location.length === 0) {
        this.setError(this.$t('errors.location.can_not_find_place_coordinates'))
        return false
      }
      if (this.location.address.house_number !== '' && (this.location.address.house_number !== location.address.house_number)) {
        this.setError(this.$t('errors.location.can_not_find_house_coordinates'))
        return false
      }
      if (this.location.address.locality && this.location.address.locality !== this.transformFetchedLocality(location) && !this.error) {
        this.setError(this.$t('errors.location.can_not_find_address_coordinates', { currentLocality: this.location.address.locality, possibleLocality: this.transformFetchedLocality(location) }))
        return false
      }
      if (this.location.address.road !== '' && !location.address.road.includes(this.location.address.road) && !this.error) {
        this.setError(this.$t('errors.location.wrong_street_name', { streetName: location.address.road }))
        return false
      }
      this.error = false
      return true
    },
    transformFetchedLocality(location) {
      let locality = null
      if ('city' in location.address) {
        locality = location.address.city
      }
      if ('town' in location.address) {
        locality = location.address.town
      }
      if ('village' in location.address) {
        locality = location.address.village
      }
      return locality
    },
    confirmAddress() {
      this.location.address_confirmed = true
    },
    unConfirmAddress() {
      this.location.address_confirmed = false
    },
    async coordinatesAreChanged() {
      await this.fetchLocationByCoordinates(this.location.position.lat, this.location.position.lng)
    },
    setLocation(location) {
      this.location.address = { ...this.location.address, ...location.address }
      this.location.address.locality = this.transformFetchedLocality(location)
      this.fetchedAddress = JSON.stringify(this.location.address)
    },
    setPosition(lat, lng) {
      this.location.position = { lat, lng }
      localStorage.setItem('lat', lat)
      localStorage.setItem('lng', lng)
    },
    setLocationViewport() {
      const circle = this.$refs.circle.mapObject
      this.$refs.map.mapObject.fitBounds(circle.getBounds())
      this.location.viewport = circle.getBounds()
    },
    setRadius() {
      this.location.radius = this.point.location_radius * 1000
    },
    setAddress() {
      this.location.address = this.point.address
      this.fetchedAddress = JSON.stringify(this.point.address)
    },
    async fetchCurrentLocation() {
      try {
        this.setLoadingState()
        const location = await locationService.currentLocation(this.$i18n.locale)
        if (location) {
          this.setLocation(location)
          this.setPosition(location.lat, location.lon)
          this.coordinatesTaken = true
        }
        this.unConfirmAddress()
        this.loading = false
      } catch (error) {
        this.setError(error)
      }
    },
    async fetchLocationBySearchQuery(query, structured = false) {
      try {
        this.setLoadingState()
        const location = await locationService.locationBySearchQuery(query, this.$i18n.locale, structured)
        if (location && this.locationIsValid(location[0])) {
          this.setLocation(location[0])
          this.setPosition(location[0].lat, location[0].lon)
          this.coordinatesTaken = true
        }
        this.unConfirmAddress()
        this.loading = false
      } catch (error) {
        this.setError(error)
      }
    },
    async fetchLocationByCoordinates(lat, lng) {
      try {
        this.setLoadingState()
        const location = await locationService.locationByCoordinates({ latitude: lat, longitude: lng }, this.$i18n.locale)
        if (location) {
          this.setLocation(location)
          this.setPosition(lat, lng)
          this.coordinatesTaken = true
        }
        this.unConfirmAddress()
        this.loading = false
      } catch (error) {
        this.setError(error)
      }
    },
    setError(error) {
      this.error = error
      this.loading = false
    },
    setLoadingState() {
      this.error = null
      this.loading = true
    },
    async onMapClick(value) {
      // place the marker on the clicked spot
      await this.fetchLocationByCoordinates(value.latlng.lat, value.latlng.lng)
    },
    onSearch(value) {
      const { location } = value
      this.setPosition(location.y, location.x)
      this.fetchLocationByCoordinates(location.y, location.x)
    },
  },

}
</script>
<style>
  .leaflet-top, .leaflet-bottom {
    z-index: auto!important;
  }
  .leaflet-container{
    z-index: 0;
  }
</style>
<style lang="scss" >
@import '@core/scss/vue/libs/vue-slider.scss';
</style>

